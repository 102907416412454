import React, { useState, useEffect } from "react";

// Import external components
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';

// Import internal components
import api from "../api/api";
import './Articles.css';
import ScrollButton from '../ScrollButton/ScrollButton';
// Import images and icons
import { Button, Form, Row, Col, Card, Modal } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';

export default function Articles() {

  // app state
  const [appState, setAppState] = useState({
    loading: true,
  });

  // Timezone offset, in hours
  const timezoneOffset = (new Date()).getTimezoneOffset() / 60;

  // data states
  const [article_count, setArticleCount] = useState(0);
  const [articles, setArticles] = useState([]);
  const [next_article_query, setNextArticleQuery] = useState('');
  const [scroll_has_more_results, setScrollHasMoreResults] = useState([]);
  const [loading_text, setLoadingText] = useState([]);
  const [mlt_articles, setMLTArticles] = useState([]);

  // filter form state and handlers
  const [filters, setFilters] = useState([]);

  // original filter from browsing
  let [originalFilterSearchItemParams, setFilterSearchItemParams] = useSearchParams();

  const handleQueryChange = (event) => {
    // update query state
    let search_item_params = filters.search_item_params;
    search_item_params.set('query', event.target.value);
    setFilterSearchItemParams(search_item_params);
    setFilters({
      ...filters,
      search_item_params: search_item_params
    });
  }

  // functions
  const handleArticles = (query) => {
    setAppState({
      ...appState,
      loading: true,
    });
    api.articles(query)
      .then((result) => {
        setArticleCount(result.data.count);
        if (result.data.count == 0) {
          setLoadingText('No results found, try another search!');
        }
        if (query.includes('last_id')) {
          setArticles(articles.concat(result.data.results));
        } else {
          setArticles(result.data.results);
        }
        setNextArticleQuery(result.data.next);
        setAppState({
          ...appState,
          loading: false
        });
      }, (error) => {
        console.log(error);
      })
      ;
  };

  useEffect(() => {
    (async () => {
      try {
        let form_state = {
          'query': '',
        };
        let search_item_params = new URLSearchParams();
        originalFilterSearchItemParams.forEach(function (value, key) {
          search_item_params.set(key, value);
        });
        setLoadingText('Loading...');
        setFilters({
          ...filters,
          form_state: form_state,
          search_item_params: search_item_params
        });
        setScrollHasMoreResults(true);
        setAppState({
          ...appState,
          display: "show",
        });
        await handleArticles("?" + search_item_params.toString());
      } catch (error) {
        // login
        console.log(error);
      }
    })();
  }, []);

  const fetchMoreArticles = () => {
    if (next_article_query == "") {
      setScrollHasMoreResults(false);
    }
    else {
      handleArticles(next_article_query);
    }
  };

  const refreshArticles = () => {
    let search_item_params = filters.search_item_params;
    search_item_params.set('query', '');
    setFilterSearchItemParams(search_item_params);
    setScrollHasMoreResults(true);
    handleArticles('');
  }

  const handleFormChange = (event) => {
    // get form state
    let search_item_params = filters.search_item_params;
    let form_state = filters.form_state;
    // update form state
    setFilters({
      ...filters,
      form_state: form_state,
      search_item_params: search_item_params
    });
    // call API
    handleArticles("?" + search_item_params.toString());
  }

  // modal stuff
  const [modalArticleTitle, setModalArticleTitle] = useState('');
  const [modalArticleSource, setModalArticleSource] = useState('');
  const [modalArticleAuthors, setModalArticleAuthors] = useState('');
  const [modalArticleDatetimeString, setModalArticleDatetimeString] = useState('');
  const [modalArticleURL, setModalArticleURL] = useState('');
  const [modalArticleHtml, setModalArticleHtml] = useState('');
  const [modalArticleMobilePDFURL, setModalArticleMobilePDFURL] = useState('');
  const [showArticleModal, setShowArticleModal] = useState(false);
  const handleArticleModalClose = () => setShowArticleModal(false);

  const handleArticleModalShow = (id_string) => {
    setAppState({
      ...appState,
      loading: true
    });

    // get article
    api.article(id_string)
      .then((result) => {
        setModalArticleTitle(result.data.title);
        setModalArticleSource(result.data.source_name);
        setModalArticleAuthors(result.data.authors);
        setModalArticleURL(result.data.url);
        setModalArticleDatetimeString(moment(result.data.date).add(timezoneOffset, 'hours').fromNow());
        if (result.data.text_html) {
          setModalArticleHtml(result.data.text_html);
        } else if (result.data.text) {
          setModalArticleHtml(result.data.text);
        } else {
          setModalArticleHtml('(no text)');
        }
      }, (error) => {
        console.log(error);
      })
      ;
    setShowArticleModal(true);
    document.activeElement.scrollTo({
      top: 0
    });
    api.mlt_articles(id_string)
      .then((result) => {
        setMLTArticles(result.data.results);
        setAppState({
          ...appState,
          loading: false
        });
      }, (error) => {
        console.log(error);
      })
      ;
  }
  // <Button variant="primary" onClick={handleShow}>

  const topPad3 = {
    marginTop: '3px',
  };

  return (
    <div className="Articles">

      <Form onSubmit={handleFormChange}>
        <Row style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}>
          <Col xs={10}><Form.Control type="text" name='query' placeholder="Search" value={originalFilterSearchItemParams.get('query')} onChange={handleQueryChange} /></Col>
          <Col xs={2}>
            <Button className="text-center" variant="primary" onClick={handleFormChange}>
              <span style={{ color: "white" }}><BsSearch size={24} style={topPad3} /></span>
            </Button>
          </Col>
        </Row>
      </Form>

      <InfiniteScroll
        dataLength={articles.length}
        next={fetchMoreArticles}
        hasMore={scroll_has_more_results}
        loader={<h4 style={{ textAlign: 'center' }}>{loading_text}</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>You have seen all {article_count} articles!</b>
          </p>
        }

        // below props only if you need pull down functionality
        refreshFunction={refreshArticles}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
        }
      >
        {articles.map((item, index) => (
          <Card className="articleCard" style={{ marginTop: '15px' }} key={index}>
            <Card.Body >
              <Card.Img variant="top" src={item.image_url} />
              {/*<a target="_blank" style={{ textDecoration: 'none', textAlign: 'center' }} href={item.url}><h5>{item.title}</h5></a>*/}
              <h5 className="text-primary" onClick={() => handleArticleModalShow(item.id)}>{item.title}</h5>
              <h6 style={{ textAlign: 'center' }}>{item.source}</h6>
              <p style={{ fontSize: 14, textAlign: 'center' }}>{item.id}&nbsp;&nbsp;|&nbsp;&nbsp;{moment(item.download_date).add(timezoneOffset, 'hours').fromNow()}</p>
              {/*<p style={{ fontSize: 14, textAlign: 'center' }}>{item.id}&nbsp;&nbsp;|&nbsp;&nbsp;{moment(item.download_date)}</p>*/}
            </Card.Body>
          </Card>
        ))}
      </InfiniteScroll>
      <ScrollButton />

      <Modal show={showArticleModal} onHide={handleArticleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalArticleTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{modalArticleSource}</h6>
          <h6>{modalArticleAuthors}</h6>
          <h6>{modalArticleDatetimeString}</h6>
          <div><p><a target="_blank" style={{ textDecoration: 'none', textAlign: 'center' }} href={modalArticleURL}>Original link</a></p></div>
          <div dangerouslySetInnerHTML={{
            __html: modalArticleHtml
          }}>
          </div>
          <br />
          <h4 className="text-primary" style={{ textAlign: 'center' }}>More like this:</h4>
          {mlt_articles.map((item, index) => (
            <Card className="mlt_articleCard" style={{ marginTop: '15px' }} key={index}>
              <Card.Body>
                <Card.Img variant="top" src={item.image_url} />
                <h5 className="text-primary" onClick={() => handleArticleModalShow(item.id)}>{item.title}</h5>
                <h6 style={{ textAlign: 'center' }}>{item.source}</h6>
                <p style={{ fontSize: 14, textAlign: 'center' }}>{moment(item.published_date).add(timezoneOffset, 'hours').fromNow()}</p>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleArticleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}
