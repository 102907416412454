// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-container { margin: 10px; padding: 10px; }
.article-category { font-weight: 600; color: #566573; font-size: 1.25em; }
.caption { font-size: 0.9em; }
.credit { font-size: 0.9em; }
.lede-large-image-v2__credit { font-size: 0.9em; }
.media-caption { font-size: 0.9em; }
.image-credits { font-size: 0.9em; }
.article_img_caption { font-size: 0.9em; }
.article__credit { font-size: 0.9em; }
.article__media-span { font-size: 0.9em; }
.group-image-caption { font-size: 0.9em; }
.article__footnote { font-size: 0.9em; }
.view_con_caption { font-size: 0.9em; }
.cons-photo-txt { font-size: 0.9em; }
.e-caption__credit { font-size: 0.8em; }
figcaption { font-size: 0.8em; }
[itemprop~="caption"] { font-size: 0.8em; }
.bt_caption { font-size: 0.8em; }
.image-caption { font-size: 0.7em; }
.image-credit { font-size: 0.7em; }
.image-copyright { font-size: 0.7em; }
.subheadline { font-weight: bold; }
.quote-attribution { font-weight: bold; }
.footnote { font-size: 0.8em; }
blockquote { font-style: italic; font-weight: bold; }`, "",{"version":3,"sources":["webpack://./src/components/Articles/Articles.css"],"names":[],"mappings":"AAAA,kBAAkB,YAAY,EAAE,aAAa,EAAE;AAC/C,oBAAoB,gBAAgB,EAAE,cAAc,EAAE,iBAAiB,EAAE;AACzE,WAAW,gBAAgB,EAAE;AAC7B,UAAU,gBAAgB,EAAE;AAC5B,+BAA+B,gBAAgB,EAAE;AACjD,iBAAiB,gBAAgB,EAAE;AACnC,iBAAiB,gBAAgB,EAAE;AACnC,uBAAuB,gBAAgB,EAAE;AACzC,mBAAmB,gBAAgB,EAAE;AACrC,uBAAuB,gBAAgB,EAAE;AACzC,uBAAuB,gBAAgB,EAAE;AACzC,qBAAqB,gBAAgB,EAAE;AACvC,oBAAoB,gBAAgB,EAAE;AACtC,kBAAkB,gBAAgB,EAAE;AACpC,qBAAqB,gBAAgB,EAAE;AACvC,aAAa,gBAAgB,EAAE;AAC/B,wBAAwB,gBAAgB,EAAE;AAC1C,cAAc,gBAAgB,EAAE;AAChC,iBAAiB,gBAAgB,EAAE;AACnC,gBAAgB,gBAAgB,EAAE;AAClC,mBAAmB,gBAAgB,EAAE;AACrC,eAAe,iBAAiB,EAAE;AAClC,qBAAqB,iBAAiB,EAAE;AACxC,YAAY,gBAAgB,EAAE;AAC9B,aAAa,kBAAkB,EAAE,iBAAiB,EAAE","sourcesContent":[".text-container { margin: 10px; padding: 10px; }\n.article-category { font-weight: 600; color: #566573; font-size: 1.25em; }\n.caption { font-size: 0.9em; }\n.credit { font-size: 0.9em; }\n.lede-large-image-v2__credit { font-size: 0.9em; }\n.media-caption { font-size: 0.9em; }\n.image-credits { font-size: 0.9em; }\n.article_img_caption { font-size: 0.9em; }\n.article__credit { font-size: 0.9em; }\n.article__media-span { font-size: 0.9em; }\n.group-image-caption { font-size: 0.9em; }\n.article__footnote { font-size: 0.9em; }\n.view_con_caption { font-size: 0.9em; }\n.cons-photo-txt { font-size: 0.9em; }\n.e-caption__credit { font-size: 0.8em; }\nfigcaption { font-size: 0.8em; }\n[itemprop~=\"caption\"] { font-size: 0.8em; }\n.bt_caption { font-size: 0.8em; }\n.image-caption { font-size: 0.7em; }\n.image-credit { font-size: 0.7em; }\n.image-copyright { font-size: 0.7em; }\n.subheadline { font-weight: bold; }\n.quote-attribution { font-weight: bold; }\n.footnote { font-size: 0.8em; }\nblockquote { font-style: italic; font-weight: bold; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
