import React, { useState } from "react";
import "./Login.css";
import api from "../api/api";
import { Button, Card, Form, Figure, Row, Col, Container } from 'react-bootstrap';

import loginLogo from "../Images/logo512.png";

export default function Login() {

  const [appState, setAppState] = useState({
    display: "hide",
    loading: false,
  });

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res;
      setAppState({ ...appState, loading: true });
      console.log("target name: " + e.target.name);
      switch (e.target.name) {
        case "Login":
          res = await api.login({ username, password });
          break;
        default:
          break;
      }
      localStorage.setItem("appLoggedin", true);
      window.location.reload();
    } catch (error) {
      setAppState({ ...appState, loading: false });
      alert(error.response.data.error);
    }
  };

  return (
    <div className="backgroundImage d-flex align-items-center justify-content-center">
      <Card>
        <Card.Body>
          <div className="text-center">
            <img src={loginLogo} width={180} />
          </div>
          <br />
          <h3 className="text-primary" style={{ textAlign: 'center' }}>Cerebro</h3>
          <br />
          <Form>
            <Form.Group className="mb-3 " controlId="formBasicText">
              <Form.Label>Username:</Form.Label>
              <Form.Control type="text" placeholder="Username" onChange={e => setUsername(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <Row className="justify-content-md-center" style={{ marginLeft: 10, marginRight: 10 }}>
                <Button className="text-center" variant="primary" type="submit" name="Login" onClick={(e) => handleSubmit(e)}>
                  <span style={{color:"white"}}>Login</span>
                </Button>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}
