// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centeredLabel {
    display: flex;
    align-items: center;
  }
  
.linkText{ color: white ; }

.linkText:hover {
    color: white ;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;AAEF,WAAW,aAAa,EAAE;;AAE1B;IACI,aAAa;EACf","sourcesContent":[".centeredLabel {\n    display: flex;\n    align-items: center;\n  }\n  \n.linkText{ color: white ; }\n\n.linkText:hover {\n    color: white ;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
