import axios from "axios";

let loggedin = localStorage.getItem("appLoggedin");
const baseUrl = "";
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    loggedin = localStorage.getItem("appLoggedin");
    return response;
  },
  function(error) {
    const originalRequest = error.config;
    if (loggedin && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post(`${baseUrl}/auth/token/refresh/`, { withCredentials: true })
        .then((res) => {
          if (res.status === 200) {
            console.log("Access token refreshed!");
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);
//functions to make api calls
const api = {
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login/`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/token/refresh/`, body);
  },
  logout: (body) => {
    return axios.post(`${baseUrl}/auth/logout/`, body);
  },
  getUserDetail: () => {
    return axios.get(baseUrl + "/api/user_detail/", { withCredentials: true });
  },
  articles: (filterString) => {
    return axios.get(baseUrl + "/api/articles/" + filterString
    );
  },
  article: (id_string) => {
    return axios.get(baseUrl + "/api/article/?id=" + id_string
    );
  },
  mlt_articles: (id_string) => {
    return axios.get(baseUrl + "/api/mlt_articles/?id=" + id_string
    );
  },
};
export default api;
