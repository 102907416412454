import React, { useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom'; // To get article ID from URL
import api from "../api/api";
import moment from 'moment';

export default function ArticleDetail() {
    const { id } = useParams(); // Get the article ID from the route
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mlt_articles, setMLTArticles] = useState([]);

    // Timezone offset, in hours
    const timezoneOffset = (new Date()).getTimezoneOffset() / 60;

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const result = await api.article(id); // Fetch the article by ID
                setArticle(result.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching the article:", error);
                setLoading(false);
            }
        };

        const fetchMLT = async () => {
            try {
                api.mlt_articles(id)
                    .then((result) => {
                        setMLTArticles(result.data.results);
                        setLoading(false);
                    }, (error) => {
                        console.log(error);
                    })
                    ;
            } catch (error) {
                console.error("Error fetching MLT:", error);
            }
        };

        fetchArticle();
        fetchMLT();

    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!article) {
        return <div>Article not found</div>;
    }

    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>{article.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>{article.source_name}</h6>
                <h6>{article.authors}</h6>
                <h6>{moment(article.date).fromNow()}</h6>
                <div><p><a target="_blank" style={{ textDecoration: 'none', textAlign: 'center' }} href={article.url}>Original link</a></p></div>
                <div dangerouslySetInnerHTML={{
                    __html: article.text_html || article.text
                }}>
                </div>
                <br />
                <h4 className="text-primary" style={{ textAlign: 'center' }}>More like this:</h4>
                {mlt_articles.map((item, index) => (
                    <Card className="mlt_articleCard" style={{ marginTop: '15px' }} key={index}>
                        <Card.Body>
                            <Card.Img variant="top" src={item.image_url} />
                            <h5 className="text-primary" onClick={() => handleArticleModalShow(item.id)}>{item.title}</h5>
                            <h6 style={{ textAlign: 'center' }}>{item.source}</h6>
                            <p style={{ fontSize: 14, textAlign: 'center' }}>{moment(item.published_date).add(timezoneOffset, 'hours').fromNow()}</p>
                        </Card.Body>
                    </Card>
                ))}
            </Modal.Body>
        </Modal>
    );
}
