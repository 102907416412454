
import styled from 'styled-components';
  
export const Heading = styled.h1`
   text-align: center;
   color: blue;
`;
  
export const ScrollContent = styled.div`
   overflowY: scroll;
   height: 2500px;
`;
  
export const Button = styled.div`
   position: fixed; 
   width: 100%;
   left: 87%;
   bottom: 30px;
   height: 50px;
   font-size: 2.5rem;
   z-index: 1;
   cursor: pointer;
   color: blue;
`
