// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundImage {
    /* background: url("../Images/backgroundImage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;*/
    height: 100vh;
  }`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;IACI;;;4BAGwB;IACxB,aAAa;EACf","sourcesContent":[".backgroundImage {\n    /* background: url(\"../Images/backgroundImage.jpg\");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;*/\n    height: 100vh;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
